.App {
  font-family: sans-serif;
  text-align: center;
}

.container {
  margin: auto;
  max-width: 800px;
  padding: 0px;
  text-align: center;
}

h1 {
  color: #333;
  font-size: 40px;
  margin-bottom: 30px;
}

input[type="text"] {
  border: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 10px;
  font-size: 20px;
  width: 60%;
}

button {
  background-color: #333;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
  padding: 10px 20px;
}

.book-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 10px;
  padding: 10px;
  list-style-type: none;
}

.book-summary {
  height: auto;
  background-color: oldlace;
  border-radius: 5pt 5pt 5pt 5pt;
}

.book-cover-container {
  justify-content: center;
  align-items: center;
}

.button-container {
  position: relative;
  top: 0%;
  left: 26%;
  transform: translate(-50%, -10%);
}

.button-run {
  position: absolute;
  width: 75px;
  height: 75px;
  text-align: left;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: -130px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 42px;
  border-radius: 50%;
  padding-right: 10px;
  background-color: transparent;
  border: solid 2px;
  border-color: white;
  color: white;
}

.button-run:before {
  margin-right: 10px;
  color: #6daae4;
}

.button-heart {
  position: absolute;
  width: 65px;
  height: 65px;
  text-align: left;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: -130px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  border-radius: 50%;
  padding-right: 10px;
  background-color: rgb(218, 134, 134);
  opacity: 0.87;
  border: solid 1px;
  border-color: white;
  color: white;
}

.button-heart:before {
  margin-right: 10px;
  color: #6daae4;
}

.book-cover {
  height: 220px;
  width: 150px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  filter: drop-shadow(7px 7px 7px rgb(133, 125, 125));
  border-radius: 5pt 5pt 5pt 5pt;
}

.book-details-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 2px;
  margin-left: 10px;
  position: relative;
}

.book-header-container {
  height: 5em;
}

.book-title {
  font-size: 18px;
  margin: 2px 0;
  margin-top: 8px;
  justify-content: flex-start;
}

.book-author {
  font-size: 14px;
  margin: 0px 0;
  justify-content: flex-start;
  font-weight: 300;
  font-style: italic;
}

.book-description {
  font-size: 14px;
  font-weight: 100;
  /*justify-content: flex-start;*/
  margin: 10px 5px;
  padding-top: 10px;
  margin-bottom: 5px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  height: 120px;
  /* Set the maximum height for the description */
  overflow: auto;
  /* Enable scrolling if the content exceeds max-height */
  position: relative;
}

.book-litres-ref {
  font-size: 14px;
  font-weight: 100;
  /*justify-content: flex-start;*/
  margin: 5px 5px;
  padding-top: 1px;
  margin-bottom: 30px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.button-book-details {
  width: 75%;
  height: 30px;
  /* background-color: #2a56a8; */
  background-color: #f07321;
  text-align: center;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  border-radius: 30pt;
  align-self: center;
}

.to-be-released {
  width: 75%;
  height: 30px;
  /* background-color: #2a56a8; */
  background-color: #f07321;
  text-align: center;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  border-radius: 30pt;
  align-self: center;
}

.button-book-litres-mini {
  width: 75%;
  height: 30px;
  background-color: #f07321;
  text-align: center;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: 5px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  border-radius: 30pt;
}

.book-card {
  /* max-width: 800px;
  width: 100%; */
}

.book-card-background {
  margin: 0px;
  padding: 50px;
}

.back-button {
  display: block;
  margin-bottom: 20px;
  background-color: #6daae4;
  color: #fff;
  font-size: 1.1rem;
  border: none;
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.book-card .book-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 20px;
}

.book-card .book-author {
  font-size: 1.2rem;
}

.book-card .book-description {
  font-size: 1rem;
  margin: 10px 3px;
  margin-bottom: 0px;
}

.back-button:hover {
  transform: scale(1.05);
}

.book-card img {
  display: block;
  height: auto;
  width: 100%;
  margin: 0px;
  padding-bottom: 0px;
  filter: drop-shadow(7px 7px 7px rgb(133, 125, 125));
  border-radius: 10pt 10pt 10pt 10pt;
}

.storymake-list-container img {
  display: block;
  height: auto;
  width: 100%;
  margin: 0px;
  padding-bottom: 10px;
  /* filter: drop-shadow(7px 7px 7px rgb(133, 125, 125));
  border-radius: 3pt; */
}

/* .book-card ul {
  list-style: none;
  text-align: center;
  padding-left: 0px;
}

.book-card li {
  text-align: center;
} */

.button-fixed {
  opacity: 0.5;
  position: fixed;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #cdcdcd;
  text-align: center;
  line-height: 60px;
  transition: transform 0.3s;
  z-index: 999;
}

.button-fixed:hover {
  transform: scale(1.1);
}

.arrow-icon {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-image: url("arrow-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.button-litres {
  width: 75%;
  height: 60px;
  background-color: #f07321;
  text-align: center;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border-radius: 50pt;
}

.button-runscenario {
  width: 75%;
  height: 60px;
  background-color: #0b32e2;
  text-align: center;
  transition: transform 0.3s;
  z-index: 999;
  margin-top: 20px;
  margin-bottom: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  border-radius: 50pt;
}

.scenarios-header {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  font-weight: bold;
}

hr.solid {
  border-top: 0.5px solid #bbb;
  width: 80%;
  margin-bottom: 35px;
  margin-top: 0px;
}

.scenario-list {
  margin-left: 0px;
  padding-left: 0px;
  list-style: none;
  padding: 20px;
}

.scenario {
  display: flex;
  position: relative;
  align-items: center;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 35px;
  background-color: rgb(197, 238, 248);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}

.scenario-header {
  display: flex;
  background-color: rgb(193, 42, 231);
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 6%;
  border-radius: 10px 10px 0px 0px;
  color: white;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.to-be-released-header {
  background-color: rgb(193, 42, 231);
  /*position: absolute;*/
  position: relative;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 6%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.available-header {
  background-color: rgb(29, 143, 57);
  /*position: absolute;*/
  position: relative;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 6%;
  border-radius: 5px 5px 0px 0px;
  color: white;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.scenario-image {
  border-radius: 3% 3%;
  padding-down: 10px;
  display: block;
}

.scenario-content {
  flex-grow: 1;
}

.scenario-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
  padding: 10px;
  padding-left: 20px;
  text-align: left;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.scenario-status p {
  font-size: 0.8rem;
  padding-left: 20px;
  margin: 0px;
  text-align: left;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-style: italic;
}

.scenario-description {
  font-size: 1rem;
  color: #050505;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  text-align: left;
  padding-left: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.scenario-warning {
  font-size: 0.8rem;
  color: #050505;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  background-color: rgb(240, 241, 138);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.scenario-warning::before {
  content: "⚠️";
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 5px;
}

.scenario-button {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 12px;
  background-color: #6fdceb;
  color: black;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.scenario-button:hover {
  background-color: #006699;
}

.scenario-button:focus,
.scenario-button:active {
  outline: none;
  background-color: #005580;
}

.shield-card {
  position: relative;
  width: 60px;
}

.shield-element {
  position: absolute;
  top: 10px;
  right: 10px;
}